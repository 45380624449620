<template>
  <a-modal
    title="新增/编辑权限"
    :maskClosable="false"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <!-- 权限名称 -->
    <div class="content-view" style="margin-bottom: 25px;">
      <span class="content-name">权限名称：</span>
      <a-input
        class="content-input"
        placeholder="请输入"
        v-model:value="name"
        :disabled="confirmLoading"
      />
    </div>
    <!-- 权限标识 -->
    <div class="content-view" style="margin-bottom: 25px;">
      <span class="content-name">权限标识：</span>
      <a-input
        class="content-input"
        placeholder="请输入"
        v-model:value="tag"
        :disabled="confirmLoading"
      />
    </div>
    <!-- 路由 -->
    <div class="content-view" style="margin-bottom: 25px;">
      <span class="content-name">路由：</span>
      <a-input
        class="content-input"
        placeholder="请输入"
        v-model:value="router"
        :disabled="confirmLoading"
      />
    </div>
    <!-- 父级 -->
    <div class="content-view">
      <span class="content-name">父级：</span>
      <a-tree-select
        class="content-input"
        :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
        :tree-data="treeData"
        placeholder="请选择"
        searchPlaceholder="输入ID或名称"
        showSearch
        treeDefaultExpandAll
        v-model:value="parentModel"
        :disabled="confirmLoading"
        @change="onTreeSelectChange"
      />
    </div>
  </a-modal>
</template>

<script>
import { permissionStore, permissionUpdate, permissionSearch } from '@/api/request'
export default {
  data () {
    return {
      treeData: [],
      visible: false,
      confirmLoading: false,
      data: {},
      source: '',
      name: '',
      tag: '',
      router: '',
      parentModel: undefined,
      parent: 0,
      // 递归使用
      number: 0
    }
  },
  methods: {
    // 显示数据
    show (source, data) {
      this.source = source
      this.data = data
      if (data) {
        this.name = data.show_name || ''
        this.tag = data.name || ''
        this.router = data.route || ''
      } else {
        this.name = ''
        this.tag = ''
        this.router = ''
      }
      this.parent = 0
      this.parentModel = undefined
      this.visible = true
      this.permissionSearch()
    },
    // 父类选择切换
    onTreeSelectChange (value) {
      this.parent = value.split('-')[0] || 0
    },
    // 已申请
    handleOk () {
      if (!this.name) {
        this.$message.error('权限名称不能为空')
        return
      }
      if (!this.tag) {
        this.$message.error('权限标识不能为空')
        return
      }
      if (this.data) {
        this.permissionUpdate()
      } else {
        this.permissionStore()
      }
    },
    // 新增权限
    permissionStore () {
      this.confirmLoading = true
      const params = {
        source: this.source,
        name: this.tag,
        show_name: this.name,
        route: this.router,
        parent_id: this.parent
      }
      permissionStore(params).then(res => {
        this.confirmLoading = false
        const { code, msg } = res
        if (code === 0) {
          this.handleCancel()
          this.$emit('reload')
          this.$message.success('新增成功')
        } else {
          this.$message.error(msg)
        }
      }).catch(err => {
        this.confirmLoading = false
        this.$message.error(err)
      })
    },
    // 权限更新
    permissionUpdate () {
      this.confirmLoading = true
      const params = {
        source: this.source,
        id: this.data.id,
        name: this.tag,
        show_name: this.name,
        route: this.router,
        parent_id: this.parent
      }
      permissionUpdate(params).then(res => {
        this.confirmLoading = false
        const { code, msg } = res
        if (code === 0) {
          this.$emit('reload')
          this.handleCancel()
          this.$message.success('更新成功')
        } else {
          this.$message.error(msg)
        }
      }).catch(err => {
        this.confirmLoading = false
        this.$message.error(err)
      })
    },
    // 父级列表
    permissionSearch () {
      permissionSearch({ source: this.source }).then(res => {
        const { code, data } = res
        if (code === 0) {
          const list = data
          const treeData = []
          list.forEach((item, index) => {
            this.number = 0
            treeData.push(this.convertSystemPermission(item))
          })
          this.treeData = treeData
        }
      })
    },
    // 转换为树行数据
    convertSystemPermission (data) {
      this.number += 1
      const dict = {}
      const childList = data.child_list || []
      const childrens = []
      dict.title = data.show_name
      dict.value = `${data.id}-${data.show_name}`
      dict.key = `${data.id}-${data.show_name}`
      // 设置当前编辑数据选择的父级
      if (this.data && data.id === this.data.parent_id) {
        this.parentModel = dict.value
        this.parent = data.id
      }
      if (this.number < 2 || this.data) {
        childList.forEach((item, index) => {
          childrens.push(this.convertSystemPermission(item))
        })
        dict.children = childrens
      }
      return dict
    },
    // 关闭弹窗
    handleCancel () {
      if (!this.confirmLoading) {
        this.visible = false
      }
    }
  }
}
</script>

<style scoped>
.content-view {
  white-space: nowrap;
}
.content-name {
  display: inline-block;
  width: 80px;
  font-weight: 400;
  font-style: normal;
  color: rgba(0, 0, 0, 0.85);
  text-align: right;
}
.content-input {
  width: 300px;
}
</style>
